<template>
  <section class="policy-list">
    <div
      class="header-title-back title-margin-bottom"
      @click="() => $router.back()">
      <span
        class="title-content hand"
      >Client</span>
      <i
        class="el-icon-arrow-right icon-little"
      ></i>
      <span class="title-litter">{{data.name}}</span>
    </div>
    <div class="block-body">
      <div class="block-content">
        <div class="flex flex-justify min-size-text">
          <div class="flex">
            <font-awesome-icon
              class="size-normal"
              :icon="data.type==1?'user':'building'"/>
            <span class="is-bold ">{{data.name}}</span>

            <v-button
              v-if="data.status!=2"
              plain
              class="button-active">Active</v-button>
            <v-button
              v-else
              plain
              class="button-blacklist">Blacklisted</v-button>
          </div>
          <div class="flex">
            <!--            <div class="flex">-->
            <!--              <font-awesome-icon-->
            <!--                class="size-normal"-->
            <!--                icon="hand-holding-usd"/>-->
            <!--              <span class="size-normal">10%</span>-->
            <!--            </div>-->
            <div class="flex ">
              <!--              <font-awesome-icon-->
              <!--                class="size-normal"-->
              <!--                icon="coins"/>-->
              <span
                class="theme-blue hand"
                @click="handlerEdit">Edit</span>
            </div>
          </div>
        </div>
        <div class="flex flex-address">
          <div class="table">
            <div></div>
            <template v-if="data.type==1">
              <div
                class="tr">
                <span>Title</span>
                <span>{{getOptionName('titleOption',data.title)}}
                </span>
              </div>
              <div
                class="tr">
                <span>Last Name </span>
                <span>{{data.last_name||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>First Name</span>
                <span>{{data.first_name||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Date of Birth</span>
                <span>{{data.birth||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Mobile Number</span>
                <span>{{data.mobile||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>HKID/Passport</span>
                <span>{{data.id_no||'-'}}
                </span>
              </div>
            </template>
            <template v-else>
              <div
                class="tr">
                <span>Company Name</span>
                <span>{{data.company_name||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>BR/CI Number</span>
                <span>{{data.id_no||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Company Phone Number</span>
                <span>{{data.company_phone||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Company Email</span>
                <span>{{data.eamil||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Nature of Business</span>
                <span>{{data.nature_id||'-'}}
                </span>
              </div>
            </template>
          </div>
          <div class="table">
            <div></div>
            <template v-if="data.type==2">
              <div
                class="tr">
                <span>Contact Person</span>
                <span>{{data.contact_name||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Contact Number</span>
                <span>{{data.contact_mobile||'-'}}
                </span>
              </div>
            </template>
            <template v-else>
              <div
                class="tr">
                <span>Email</span>
                <span>{{data.email||'-'}}
                </span>
              </div>
              <div
                class="tr">
                <span>Occupation</span>
                <span>{{data.occ||'-'}}
                </span>
              </div>
            </template>
            <div
              class="tr">
              <span>Address</span>
              <span>{{address||'-'}}
              </span>
            </div>
            <div
              v-if="data.status==2"
              class="tr">
              <span>Reason</span>
              <span>{{data.blacklist_reason||'-'}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <el-tabs
        v-model="activeName"
        @tab-click="handleTab"
      >
        <el-tab-pane
          v-for="(item,index) in tablList"
          :key = index
          :label="item.name"
          :name="item.id"></el-tab-pane>
      </el-tabs>
    </div>

    <policy-list
      v-if="activeName=='0'"
      :type="2"
      :isPending="false"
      :summary="data"
      @refresh="handlerSummaryRefresh"
    ></policy-list>
    <claim-list
      v-if="activeName=='1'"
      :summary="data"></claim-list>
  </section>
</template>

<script>
import policyList from '@/views/claim/componment/policy-list'
import {getClientDetail,getClientClaimSummary} from '@api/clients'
import createReferrerDialog from '@/views/components/dialog/createReferrerDialog'
import { mapState, mapActions } from 'vuex'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import ClaimList from '@/views/claim/componment/claim-list'
import MixinCheckBlack from '@/mixins/checkBlackList'
export default {
  name: 'referral',

  components:{
    ClaimList,
    policyList,
  },
  mixins: [MixinOptions,MixinRules],
  props:{
  },
  data(){
    return {
      activeName:'0',
      show:false,
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      data:{},
      summary:{}
    }
  },
  computed:{
    ...mapState('claim',['claim_tab']),
    tablList(){
      return [
        {
          name:`Policy Record (${this.data.policy_number||0})`,
          id:'0'
        },
        {
          name:`Claim Record  (${this.data.claim_number||0})`,
          id:'1'
        }
      ]
    },
    address() {
      let {address} = this.data||{}
      if(address)
      {
        let arr = [
          address.room?`ROOM ${address.room} `:'',
          address.floor?`${address.floor}/F `:'',
          address.block?`BLOCK ${address.block} `:'',
          address.building,
          address.street,
          this.getAreaName('district', address.district),
          this.getAreaName('area', address.area),
          this.getAreaName('region', address.region)
        ]
        return arr.filter(item => item).join(', ')
      }else  return ''


    }
  },
  created() {
    this.activeName = this.claim_tab||'0'
    this.network().getClientDetail()
  },
  methods:{
    ...mapActions('claim',['setClaimTab']),
    handleTab(){
      this.setClaimTab(this.activeName)
    },
    handlerSummaryRefresh(){
      // if(this.activeName==1){
      //   this.network().getReferrerUnsettleSummary()
      // }
      // else if(this.activeName==4)
      //   this.network().getReferrerPendingSummary()
    },
    handlerEdit(){
      let {id} = this.$route.params
      this.$router.push('/member/clients/edit/'+id)
    },
    handlerRefresh(params){
      this.network().editReferrer(params)
    },
    statementRefresh(){
      this.handleClick()
    },
    handleClick() {
      this.setStatementTab(this.activeName)
      this.network().getClientClaimSummary()
    },
    network() {
      return {
        getClientDetail: async () => {
          const { data } = await getClientDetail({
            client_id:this.$route.params.id
          })
          this.data = data
        },
        getClientClaimSummary: async () => {
          const { data } = await getClientClaimSummary({
            client_id:this.$route.params.id
          })
          this.summary = data
          // this.pageConfig.total = data.total
        }

      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
.block-body{
  background: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  .flex{
    align-items: center;
  }
  .is-bold{
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-right: 16px;
  }
  .size-normal{
    color: $disable;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 16px;
  }
  .block-content{
    padding: 20px;
  }
  ::v-deep .el-tabs__header{
    padding-left: 20px;
    margin: 0;
    .el-tabs__nav-wrap::after{
      height: 0;
    }
  }
}
.title-content{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}
.title-margin-bottom{
  margin-bottom: 40px;
  .icon-little{
    margin: 0 14px;
    font-size: 12px;
  }
}
.flex-content{
  margin-bottom: 20px;
  .title-tip{
    color: #4A5568;
    font-size: 16px;
    font-weight: 600;
    span{
      margin-right: 15px;
    }
  }
}
.flex-address{
  align-items: start!important;
  margin-top: 20px;
  .table{
    flex: 1;
    &:first-child{
      margin-right: 20px;
    }
  }
}
/deep/.el-tabs__active-bar{
  //max-width: 110px!important;
}
</style>
