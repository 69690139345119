<template>
  <section>
    <div class="search-list flex">
      <span
        v-for="(item,index) in category"
        :key="index"
        class="search-list-content"
        :class="{'active':class_id==item.class_id}"
        @click="handleSearch(item.class_id)">{{item.class_name}} ({{item.claim_number}})</span>
    </div>
    <div
      class="flex-justify flex flex-content">
      <div
        class="flex title-tip">
        <div class="flex-cloumn">
          <span>Claims</span>
          <span class="number">{{claim_number}}</span>
        </div>
      </div>
      <v-button
        class="button-theme mg-r-8"
        @click="resetFilter">Clear</v-button>
    </div>
    <claim-list-content
      :is-edit="false"
      :list="tableData"></claim-list-content>
    <template v-if="tableData.length>0">
      <v-pagination
        :total="pageConfig.total"
        :current="pageConfig.currentPage"
        @handle-current-change="currentChange"
        @handle-size-change="handleSizeChange"/>
    </template>
  </section>
</template>

<script>
import ClaimListContent from '@/views/claim/componment/claim-list-content'
import {getClientClaimSummary, getClientClaimList, getClientPolicyList} from '@api/clients'
import MixinOptions from '@/mixins/options'
import MixinFormat from '@/mixins/format'
import MixinFilter from '@/mixins/filter'
export default {
  name: 'claim-list',
  components: {ClaimListContent},
  mixins: [MixinOptions,MixinFormat,MixinFilter],
  props:{
    summary:{
      type:Object,
      default:()=>({})
    }
  },
  data(){
    return {
      search_summary:{
        category:[]
      },
      category:[],
      class_id:0,
      date:'',
      issueDate:'',
      effectiveDate:'',
      expiryDate:'',
      company_id:'',
      product_id:'',
      policy_holder:'',
      policy_num:'',
      policy_status:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      multipleSelection: [],
      tableData:[],
      claim_number:0,
      filterData:{
        date:'',
        issueDate:'',
        effectiveDate:'',
        expiryDate:'',
        company_id:'',
        product_id:'',
        policy_holder:'',
        policy_num:'',
        policy_status:'',
      },
      filterTableKey:'clientClaim'
    }
  },
  computed:{
    client_id(){
      let {id} = this.$route.params
      return id
    }
  },
  async mounted() {
    await this.network().getClientClaimSummary()
    this.network().getClientClaimList()
  },
  methods:{
    handleSearch(class_id){
      this.class_id = class_id
      this.pageConfig.currentPage = 1
      this.getList()
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.getList()
    },
    handleSizeChange(size) {
      this.pageConfig.size = size
      this.getList()
    },
    handleChange(){
      this.pageConfig.currentPage = 1
      this.getList()
    },
    handlerChange(){
      this.pageConfig.currentPage = 1
      this.getList()
    },
    getList(){
      this.network().getClientClaimList()
    },
    network() {
      return {
        getClientClaimSummary: async () => {
          const { data } = await getClientClaimSummary({
            client_id:this.$route.params.id
          })
          let {category} = data
          this.category = category
          this.search_summary = data
        },
        getClientClaimList: async (params) => {
          const {data} = await getClientClaimList({
            client_id: this.$route.params.id,
            size: this.pageConfig.size,
            page: this.pageConfig.currentPage,
            class_id:this.class_id,
          })
          let {meta} = data
          this.tableData = data.data
          this.pageConfig.total = meta.total
          this.claim_number = meta.total
        },

      }
    }
  }
}
</script>

<style scoped lang="scss">
.flex-content{
  margin-bottom: 20px;
  margin-top: 20px;
  .title-tip{
    font-size: 16px;
    font-weight: 600;
    .flex-cloumn:first-child{
      //color: #000;
    }
    .flex-cloumn{
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      color: #4A5568;
      justify-content: space-between;
      .number{
        font-size: 28px;
        color: #718096;
      }
    }
  }
  .flex-button{
    align-self: flex-end;
  }
}
.search-list{
  .search-list-content{
    border-radius: 80px;
    padding: 2px 12px;
    border: 1px solid $primary;
    color: $primary;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-right: 12px;
    background: #fff;
    cursor: pointer;
    &.active{
      border: 2px solid $primary;
      background: $primary-bg;
    }
  }
}
</style>
